
import React, { useRef, useState, useEffect } from 'react';

import { Box, Button, Typography, styled } from '@mui/joy';

import { Img } from './Img'

import { InputField } from './InputField'; 

import i18n from '@/i18n'
import { File, MusicNote, PlayCircle, WaveSine, Waveform, Waves } from '@phosphor-icons/react';

import { HBox } from './HBox';

export const AudioFrame = styled(
  Box,
  {
    slot: 'root',
    name: "AudioFrame"
  }
)(({ theme }) => ({ 
  padding: 0,
  margin: 0,
  borderWidth: theme.variants.outlined.borderWidth,
  alignSelf: 'flex-start',
  aspectRatio: '1/1',
  borderColor: theme.variants.outlined.neutral.borderColor,
  borderStyle: 'solid', 
}))

export function AudioEditor({ 
 audioUrl: defaultAudioUrl,
 renderPlayer,
 playable,
 readOnly,
 onChange = () => console.warn('On change not implemented'),
 onChangeURL = () => console.warn('onChangeURL not implemented')
}) {
  const [audioUrl, setAudioUrl] = useState(defaultAudioUrl)
  const [file, setFile] = useState(null)
  const fileInputRef = useRef(null)
  const handleBrowseClick = (event) => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }
  const handleFileInputChange = (event) => {
    const file = event.target.files[0]
    setFile(file)
    const reader = new FileReader()
    reader.readAsDataURL(file)
  }

  const handleRemoveClick = (event) => {
    setAudioUrl(null)
  }

  useEffect(() => {
    onChangeURL(audioUrl)
  }, [audioUrl])
  useEffect(() => {
    onChange(file)
  }, [file])

  return (
    <Box  sx={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', gap: 1 }}>
      {playable ?
        <AudioFrame sx={{ flexDirection: 'column', border: '1pt solid currentColor', display: 'flex', alignItems: 'center', justifyContent: 'center', aspectRatio: '1/1', width: '120pt', height: '120pt' }}>
          <Waveform size={100} /> 
        </AudioFrame> 
      :
        <Img style={{ aspectRatio: '1/1', width: '120pt', height: '120pt' }} />
      }
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
        <HBox>
          <Button variant="outlined" disabled={readOnly} onClick={handleBrowseClick}>{i18n.t('browse')}</Button>
          <Button variant="outlined" disabled={readOnly} onClick={handleRemoveClick}>{i18n.t('remove')}</Button>
          <input readOnly={readOnly} type="file" accept=".wav,audio/wav" onChange={handleFileInputChange} ref={fileInputRef} style={{ display: 'none' }} />
        </HBox>
        <br />
        {playable && <audio controls src={audioUrl} />}
        {file && <Typography sx={{ textAlign: 'center'}}>A new audio file has been selected will be uploaded on save</Typography>}
      </Box>
    </Box>
  )
}

export function AudioField({
  audioUrl,
  label,
  playable,
  renderPlayer,
  defaultValue,
  errors,
  onChange,
  readOnly,
  onChangeURL
}) {
  return (
    <InputField
      label={label}
      errors={errors}
    >
      <AudioEditor playable={playable} renderPlayer={renderPlayer} readOnly={readOnly} defaultValue={defaultValue} onChangeURL={onChangeURL} audioUrl={audioUrl} onChange={onChange} />
    </InputField>
  )
}
