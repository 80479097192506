import React from 'react';

import { ExclamationMark, PauseCircle } from '@phosphor-icons/react';
import { PlayCircle } from '@phosphor-icons/react/dist/ssr';

import { PlayerContext } from '@/contexts/PlayerContext';

export function PlayButton({
    recording: playerRecording,
    size=28
}) {
    const {
        recording,
        setRecording,
        status,
        setStatus,
        pause,
        play
    } = React.useContext(PlayerContext)
    const handlePlayerClick = (event) => {
        event.stopPropagation()
        if (playerRecording?.id && (recording?.id === playerRecording?.id) && !!playerRecording.id) {
            if (status === 'playing') { 
                pause()
            } else {
                play()
            }
        } else {
            setRecording(playerRecording)
            setTimeout(() => {
                play()
            }, 100)
        }
    }

    return (
        <a onClick={handlePlayerClick}>
            {(recording?.id === playerRecording?.id && !!playerRecording?.id) ? (status === 'playing' ? <PauseCircle size={size} weight="fill" /> : (status !== 'error' ? <PlayCircle size={size} weight="fill" /> : <ExclamationMark size={size} color="#880000" />)) : <PlayCircle size={size} weight="fill" />}
        </a>
    )
}