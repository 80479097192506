import { PlayerContext } from '@/contexts'
import React from 'react'
import { useContext } from 'react'
import { HBox } from './HBox'
import { PlayButton } from './PlayButton'
import { useRef } from 'react'
import { useEffect } from 'react'
import { Typography } from '@mui/joy'
import { Box } from '@mui/joy'
import { VBox } from './VBox'
import { Img } from './Img'
import { Link } from 'react-router-dom'

export function Player({

}) {
    const { status, setStatus, play, pause, recording, audioRef, setAudioRef } = useContext(PlayerContext)

    const playerRef = useRef(null)

    useEffect(() => {
        setAudioRef(playerRef)
    }, [playerRef])
    
    const handleError = event => {
        setStatus("error")
    }

    const handlePlay = event => {
        setStatus("playing")
    }

    const handlePause = event => {
        setStatus("paused")
    }
    
    return (
        <HBox sx={{ flex: 1, alignItems: 'center', gap: 1 }}>
            <PlayButton size={48} recording={recording} />
            <audio onPlay={handlePlay} onPause={handlePause} onError={handleError} src={recording?.audio_url} ref={playerRef} />
            <Box sx={{ flex: 1 }} />
            {recording && (
                <HBox style={{ flex: 1, p: 5, background: 'rgba(127, 127, 127, .1' }}>
                    <Img style={{ height: 58 }} src={recording?.image_url} />
                    <VBox style={{ flex: 1 }}>
                        <Link to={`/dashboard/recording/${recording.id}`}>
                            <Typography fontWeight={500}>{recording?.name}</Typography>
                        </Link>
                        <Typography >{recording?.recording_artists?.map(ra => <> <Typography component={Link} to={`/dashboard/artist/${ra.artist.id}`}>{ra.artist.name}</Typography> · </>)}</Typography>
                    </VBox>
                </HBox>
            )}
        </HBox>
    )
}